<template>
  <div>
    <v-data-table
      :headers="headers"
      :footer-props="footerProps"
      :items="siteLocations"
      :loading="isLoading"
      :server-items-length="siteLocationsCount"
      :options.sync="options"
    >
      <template v-slot:item.name="{ item }">
        <router-link
          :to="{
            name: 'SiteLocationsView',
            params: { id: item.id, engagementId: engagementId },
          }"
          >{{ item.attributes.name }}</router-link
        >
      </template>
      <template
        v-slot:item.rmsSiteIdentification="{ item, index }"
        v-if="!isGlobalAdmin"
      >
        <div class="mx-auto" style="width: 100px">
          <template v-if="currentlyEditingIndexTrialSiteId === index">
            <v-text-field
              class="trial-site-id-edit"
              dense
              hide-details
              autofocus
              hide-spin-buttons
              placeholder="N/A"
              v-model="item.attributes.rmsSiteIdentification"
              append-outer-icon="mdi-check"
              @blur="
                updateTrialSiteId(index, item.attributes.rmsSiteIdentification)
              "
              @click:append-outer="
                updateTrialSiteId(index, item.attributes.rmsSiteIdentification)
              "
            >
            </v-text-field>
          </template>
          <div class="trial-site-id-static" v-else>
            <template v-if="item.attributes.rmsSiteIdentification">
              {{ item.attributes.rmsSiteIdentification }}
            </template>
            <!-- Only engagement site locations' trial site id are editable right now -->
            <v-btn
              v-if="
                item.engagementSiteLocation !== undefined && allowEditTrialSites
              "
              class="edit-trial-site-id-btn mr-n5"
              @click="editTrialSiteId(index)"
              elevation="0"
              icon
              x-small
            >
              <v-icon>mdi-pencil</v-icon>
              <span class="d-none normal-font" aria-hidden="true">Edit</span>
            </v-btn>
          </div>
        </div>
      </template>
      <template v-slot:item.siteLocationType="{ item }">
        {{ item.relationships.siteLocationType.data.id | humanize }}
      </template>
      <template v-slot:item.siteLocationIrbType="{ item }">
        {{ item.relationships.siteLocationIrbType.data.id | humanize }}
      </template>
      <template v-slot:item.location="{ item }">
        <address-formatted
          v-if="!!item.attributes.location"
          textAlign="left"
          :city="item.attributes.location.city"
          :state="item.attributes.location.state"
          :single-line="singleLineAddress"
        ></address-formatted>
      </template>
      <template v-slot:item.activeTrialsCount="{ item }">
        {{ item.attributes.activeTrialsCount }}
      </template>
      <template v-slot:item.totalTrialsCount="{ item }">
        {{ item.attributes.totalTrialsCount }}
      </template>
      <template v-if="showRadius" v-slot:item.radius="{ item, index }">
        <div class="mx-auto" style="width: 100px">
          <template v-if="currentlyEditingIndexRadius === index">
            <v-text-field
              class="radius-edit"
              dense
              hide-details
              autofocus
              type="number"
              hide-spin-buttons
              :placeholder="defaultRadius?.toString()"
              v-model="item.attributes.radius"
              append-outer-icon="mdi-check"
              @blur="updateRadius(index, item.attributes.radius)"
              @click:append-outer="updateRadius(index, item.attributes.radius)"
            >
            </v-text-field>
          </template>
          <div class="radius-static" v-else>
            <template v-if="item.attributes.radius > 0">
              {{ item.attributes.radius }}
            </template>
            <!-- No radius assigned, show the default -->
            <span class="text--disabled" v-else>
              {{ defaultRadius }}
            </span>
            <!-- Only engagement site locations' radius are editable right now -->
            <v-btn
              v-if="
                item.engagementSiteLocation !== undefined && allowEditTrialSites
              "
              class="edit-radius-btn mr-n5"
              @click="editRadius(index)"
              elevation="0"
              icon
              x-small
            >
              <v-icon>mdi-pencil</v-icon>
              <span class="d-none normal-font" aria-hidden="true">Edit</span>
            </v-btn>
          </div>
        </div>
      </template>
      <template v-slot:item.enabled="{ item, index }" v-if="!isGlobalAdmin">
        <div class="d-flex justify-center align-center">
          <!-- Displays if the record is active ONLY for a given engagement -->
          <div
            cols="6"
            v-if="item.engagementSiteLocation !== undefined"
            class="px-3 text-no-wrap"
          >
            <v-switch
              label="Enabled"
              @change="toggleEnabled(index)"
              v-model="item.attributes.isActiveForEngagement"
              v-if="allowEditTrialSites"
            >
            </v-switch>
            <span class="text--disabled" v-else>
              {{
                item.attributes.isActiveForEngagement ? "Enabled" : "Disabled"
              }}
            </span>
          </div>
        </div>
      </template>
      <template v-slot:item.isActive="{ item }">
        <div class="d-flex justify-center align-center">
          <div
            :cols="item.engagementSiteLocation !== undefined ? 6 : 12"
            class="px-3 text-no-wrap"
          >
            <v-icon v-if="item.attributes.isActive" color="green">
              mdi-check-circle
            </v-icon>
            <v-icon v-else color="red">mdi-cancel</v-icon>
          </div>
        </div>
      </template>
      <template v-slot:item.associated="{ item, index }">
        <v-switch
          label="Associated"
          @click.stop="toggleAssociated(index)"
          v-model="item.associated"
        >
        </v-switch>
      </template>
      <template v-slot:no-data>
        <no-data-alert />
      </template>
    </v-data-table>
    <v-dialog
      transition="dialog-top-transition"
      max-width="600"
      v-model="dialogOpen"
      persistent
    >
      <v-card>
        <v-toolbar color="primary" dark
          >Engagement Site Location Meta Info</v-toolbar
        >
        <v-card-text>
          <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <v-form
              ref="form"
              class="mt-3"
              @submit.prevent="handleSubmit(submit)"
            >
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <validation-provider
                      v-slot="{ errors }"
                      name="rmsSiteIdentification"
                      rules="required"
                    >
                      <v-text-field
                        :error-messages="errors"
                        outlined
                        v-model="rmsSiteIdentification"
                        label="Site Identification"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="4">
                    <v-checkbox v-model="isActive" label="Active"></v-checkbox>
                  </v-col>
                  <v-col cols="12" sm="8">
                    <validation-provider
                      v-slot="{ errors }"
                      name="radius"
                      rules="required"
                    >
                      <v-text-field
                        :error-messages="errors"
                        outlined
                        type="number"
                        v-model="radius"
                        label="Radius"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
              </v-container>
              <v-card-actions class="justify-lg-space-between">
                <v-btn class="warning" @click.stop="cancel">Cancel</v-btn>
                <v-btn type="submit" class="primary">Save</v-btn>
              </v-card-actions>
            </v-form>
          </validation-observer>
        </v-card-text>
      </v-card>
      <v-snackbar
        v-model="errorSnackbar"
        :multi-line="true"
        color="warning"
        timeout="-1"
        outlined
        absolute
        bottom
        right
        transition="slide-x-reverse-transition"
      >
        {{ error }}

        <template v-slot:action="{ attrs }">
          <v-btn
            icon
            color="warning"
            v-bind="attrs"
            @click="errorSnackbar = false"
          >
            <v-icon>mdi-close</v-icon>
            <span class="d-none" aria-hidden="true">Close</span>
          </v-btn>
        </template>
      </v-snackbar>
    </v-dialog>
    <v-snackbar
      v-model="associationSnackbar"
      :multi-line="true"
      color="primary"
      timeout="2000"
      outlined
      absolute
      bottom
      centered
      transition="slide-x-reverse-transition"
    >
      The site location was successfully {{ associationStatus }}.
      <template v-slot:action="{ attrs }">
        <v-btn
          icon
          color="warning"
          v-bind="attrs"
          @click="associationSnackbar = false"
        >
          <v-icon>mdi-close</v-icon>
          <span class="d-none" aria-hidden="true">Close</span>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import AddressFormatted from "@admin/components/AddressFormatted.vue";
import NoDataAlert from "@admin/components/NoDataAlert.vue";
import _ from "lodash";
import { required } from "vee-validate/dist/rules";
import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
import { humanize } from "@utils/filters/stringHelpers.js";

extend("required", {
  ...required,
  message: "Please provide a value.",
});

export default {
  name: "SiteLocationsTable",
  emits: ["saveRadius", "toggleEnabled"],
  components: {
    NoDataAlert,
    AddressFormatted,
    ValidationObserver,
    ValidationProvider,
  },
  filters: {
    humanize,
  },
  computed: {
    ...mapState("engagementsSiteLocations", ["error"]),
    headers() {
      const headers = [];
      headers.push({
        text: "Name",
        value: "name",
      });
      if (
        this.$router.currentRoute.path !== "/site-locations/" &&
        this.$router.currentRoute.name !== "SiteAssociations"
      ) {
        headers.push({
          text: "Trial Site ID",
          value: "rmsSiteIdentification",
          sortable: false,
        });
      }
      if (this.$router.currentRoute.name !== "SiteAssociations") {
        headers.push({
          text: "Type of Site",
          value: "siteLocationType",
          sortable: false,
        });
      }
      if (
        this.$router.currentRoute.path !== "/site-locations/" &&
        this.$router.currentRoute.name !== "SiteAssociations"
      ) {
        headers.push({
          text: "IRB Type",
          value: "siteLocationIrbType",
          sortable: false,
        });
      }
      headers.push({
        text: "Address",
        value: "location",
        sortable: false,
      });
      if (this.showRadius) {
        headers.push({
          text: "Radius",
          value: "radius",
          sortable: false,
          align: "center",
        });
      }
      if (
        this.$router.currentRoute.path !== "/site-locations/" &&
        this.$router.currentRoute.name !== "SiteAssociations"
      ) {
        headers.push({
          text: "Enabled",
          value: "enabled",
          sortable: false,
          align: "center",
        });
      }

      if (this.$router.currentRoute.path == "/site-locations/") {
        headers.push({
          text: "Active Trials",
          value: "activeTrialsCount",
          sortable: false,
          align: "center",
        });
        headers.push({
          text: "Total  Trials",
          value: "totalTrialsCount",
          sortable: false,
          align: "center",
        });
      }

      if (this.$router.currentRoute.name !== "SiteAssociations") {
        headers.push({
          text: "Status",
          value: "isActive",
          align: "center",
        });
      }
      if (this.$router.currentRoute.name === "SiteAssociations") {
        headers.push({
          text: "Actions",
          value: "associated",
          sortable: false,
        });
      }

      return headers;
    },
    ...mapState(["footerProps"]),
    isGlobalAdmin() {
      return this.$router.currentRoute.path === "/site-locations/";
    },
    pageAndSortData() {
      const data = (({ page, itemsPerPage, sortBy }) => ({
        page,
        limit: itemsPerPage,
        sortBy,
      }))(this.options);

      if (this.options.sortBy.length) {
        data.sortBy = [];
        for (let i = 0; i < this.options.sortBy.length; i++) {
          const sortField = this.options.sortBy[i];
          const desc = this.options.sortDesc[i] === true ? "-" : "";
          data.sortBy.push(desc + sortField);
        }
      }

      return data;
    },
    ...mapGetters(["acceptRoles"]),
    allowEditTrialSites() {
      return this.acceptRoles(["Manager", "Admin", "Editor", "Super User"]);
    },
  },
  data() {
    return {
      currentlyEditingIndexRadius: undefined,
      currentlyEditingIndexTrialSiteId: undefined,
      dialogOpen: false,
      dialogIndex: null,
      associationSnackbar: false,
      associationStatus: "associated",
      errorSnackbar: false,
      isActive: true,
      radius: this.defaultRadius,
      radiusCache: {},
      trialSiteIdCache: {},
      rmsSiteIdentification: "",
      search: "",
      options: {
        page: 1,
        itemsPerPage: 20,
        sortBy: [],
        sortDesc: [],
      },
    };
  },
  methods: {
    ...mapActions("engagementsSiteLocations", [
      "associateEngagementSiteLocation",
      "deleteEngagementSiteLocation",
    ]),

    cancel() {
      this.dialogOpen = false;
      this.siteLocations[this.dialogIndex].associated = false;
    },
    editRadius(index) {
      this.currentlyEditingIndexRadius = index;
      // cache the value before editing so we can compare later
      this.radiusCache[index] = this.siteLocations[index]?.attributes.radius;
    },
    editTrialSiteId(index) {
      this.currentlyEditingIndexTrialSiteId = index;
      // cache the value before editing so we can compare later
      this.trialSiteIdCache[index] =
        this.siteLocations[index]?.attributes.rmsSiteIdentification;
    },
    async submit() {
      const siteLocation = this.siteLocations[this.dialogIndex];
      await this.associateEngagementSiteLocation({
        engagementId: this.engagementId,
        siteLocationId: siteLocation.id,
        isActive: this.isActive,
        radius: this.radius,
        rmsSiteIdentification: this.rmsSiteIdentification,
      }).then((response) => {
        if (response !== false) {
          this.associationSnackbar = true;
          this.associationStatus = "associated";
          this.$emit("updateSiteLocations", this.pageAndSortData);
          this.dialogOpen = false;
          this.dialogIndex = null;
        } else {
          this.errorSnackbar = true;
        }
      });
    },
    async toggleAssociated(index) {
      const siteLocation = this.siteLocations[index];
      if (siteLocation.associated) {
        this.dialogOpen = true;
        this.dialogIndex = index;
        this.isActive = true;
        this.radius = this.defaultRadius;
        this.rmsSiteIdentification = "";
      } else {
        const engagementSiteLocation = this.engagementsSiteLocations.filter(
          (esl) => {
            if (esl.relationships.siteLocation.data.id === siteLocation.id) {
              return esl.relationships.engagement.data.id;
            }
          }
        );
        if (engagementSiteLocation.length > 0) {
          const response = await this.deleteEngagementSiteLocation(
            engagementSiteLocation
          );
          if (response !== false) {
            this.associationSnackbar = true;
            this.associationStatus = "unassociated";
            this.$emit("updateSiteLocations", this.pageAndSortData);
          }
        }
      }
    },
    toggleEnabled(index) {
      const siteLocation = this.siteLocations[index];
      if (siteLocation !== undefined) {
        this.$emit("toggleEnabled", index);
      }
    },
    updateRadius(index, radius) {
      this.currentlyEditingIndexRadius = undefined;
      // compare the cached value, only save if different
      if (
        this.radiusCache[index]?.toString() !==
        this.siteLocations[index]?.attributes?.radius?.toString()
      ) {
        this.$emit("saveRadius", { index: index, radius: radius });
      }
    },
    updateTrialSiteId(index, trialSiteId) {
      this.currentlyEditingIndexTrialSiteId = undefined;
      // compare the cached value, only save if different
      if (
        this.trialSiteIdCache[index]?.toString() !==
        this.siteLocations[index]?.attributes?.rmsSiteIdentification?.toString()
      ) {
        this.$emit("saveTrialSiteId", {
          index: index,
          trialSiteId: trialSiteId,
        });
      }
    },
  },
  props: {
    defaultRadius: {
      required: false,
      type: [String, Number],
      default: () => 0,
    },
    engagementId: {
      required: false,
      type: String,
      default: () => undefined,
    },
    engagementsSiteLocations: {
      required: false,
      type: Array,
      default: () => [],
    },
    isLoading: {
      required: false,
      type: Boolean,
      default: () => false,
    },
    showRadius: {
      required: false,
      type: Boolean,
      default: () => false,
    },
    singleLineAddress: {
      required: false,
      type: Boolean,
      default: () => false,
    },
    siteLocations: {
      required: true,
      type: Array,
      default: () => [],
    },
    siteLocationsCount: {
      required: true,
      type: Number,
      default: () => 0,
    },
  },
  watch: {
    pageAndSortData: {
      async handler(currentValue, oldValue) {
        if (_.isEqual(currentValue, oldValue)) return;

        this.$emit("updateSiteLocations", currentValue);
      },
      deep: true,
    },
  },
};
</script>

<style>
.normal-font {
  font-size: 1rem;
}
.trial-site-id-static:not(:hover) .edit-trial-site-id-btn,
.radius-static:not(:hover) .edit-radius-btn {
  opacity: 0;
}
.trial-site-id-edit.v-input input,
.radius-edit.v-input input {
  text-align: center;
}
</style>
