<template>
  <div :class="textAlignClass">
    <template v-if="streetAddress.length > 0">{{ streetAddress }}</template>
    <template v-if="unitNumber.length > 0">{{ unitNumber }}</template>
    <template v-if="city.length > 0 || state.length > 0 || zipCode.length > 0">
      <br v-if="!singleLine" />
      <template v-if="city.length > 0">
        {{ city }}
        <template v-if="state.length > 0 || zipCode.length > 0">, </template>
      </template>
      <template v-if="state.length > 0">
        {{ state }}
      </template>
      <template v-if="zipCode.length > 0">
        {{ zipCode }}
      </template>
    </template>
    <template v-if="country.length > 0">{{ country }}</template>
  </div>
</template>

<script>
export default {
  name: "AddressFormatted",
  props: {
    textAlign: {
      required: false,
      type: String,
      default: () => "",
      validator(value) {
        return ["left", "center", "right"].includes(value);
      },
    },
    streetAddress: {
      required: false,
      type: String,
      default: () => "",
    },
    unitNumber: {
      required: false,
      type: String,
      default: () => "",
    },
    city: {
      required: false,
      type: String,
      default: () => "",
    },
    state: {
      required: false,
      type: String,
      default: () => "",
    },
    zipCode: {
      required: false,
      type: String,
      default: () => "",
    },
    country: {
      required: false,
      type: String,
      default: () => "",
    },
    singleLine: {
      required: false,
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    textAlignClass(state) {
      switch (state.textAlign) {
        default:
          return "";
        case "left":
          return "text-left";
        case "center":
          return "text-center";
        case "right":
          return "text-right";
      }
    },
  },
};
</script>
